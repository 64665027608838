import { graphql, PageProps } from 'gatsby';
import { PostEntryProps } from '../types';
import Post from '../components/Post';
import SEO from '../components/Seo';

interface DataProps {
  post: PostEntryProps;
  relatedPosts?: {
    nodes?: PostEntryProps[];
  };
}

export function Head({
  location,
  data,
}: {
  location: { pathname: string };
  data: DataProps;
}) {
  const { post } = data;

  return (
    <SEO
      title={post.title}
      description={post.excerpt}
      pathname={location.pathname}
    />
  );
}

export default function PostTemplate({ data }: PageProps<DataProps>) {
  const { post, relatedPosts } = data;

  return <Post post={post} relatedPosts={relatedPosts} />;
}

export const pageQuery = graphql`
  query ($id: String!) {
    post: wpStory(id: { eq: $id }) {
      ...StoryEntry
      content
    }
    relatedPosts: allWpStory(filter: { id: { nin: [$id] } }, limit: 3) {
      nodes {
        ...StoryEntry
      }
    }
  }
`;
